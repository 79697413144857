<template>
  <div>

    <v-snackbar v-model="snackbarError" :timeout="4000" top color="error">
      <span>Opération échouée.</span>
      <v-btn text color="white" @click="snackbarError = false">Fermer</v-btn>
    </v-snackbar>

    <v-dialog v-model="dialog" width="500">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-alert v-if="error" type="error">
                <ul class="alert alert-danger">
                  <li v-for="(errorD, index) in error.details" :key="index">{{ errorD[0] }}</li>
                </ul>
              </v-alert>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field hide-details="auto" :label="$t('student')" :value="getStudentName(editedItem)"
                      readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select v-model="editedItem.classe_id" hide-details="auto" :items="classes" :label="$t('classe')"
                      :rules="[rules.required]" item-value="id" item-text="level">
                      <template v-slot:selection="{ item }">{{ item.level }} {{ item.schedule }}</template>
                      <template v-slot:item="data">{{ data.item.level }} - {{ data.item.schedule }} -
                        {{ data.item.nb_students }}/{{ data.item.max_students }}</template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="close">{{ $t('actions.cancel') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="save">{{ $t('actions.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche" single-line
          hide-details></v-text-field>
      </v-card-title>

      <v-data-table must-sort :headers="headers" :search="search" :items="inscriptions" :options.sync="options"
        :server-items-length="totalInscriptions" :loading="loading" class="elevation-1" group-by="tutor">
        <template slot="group.header" slot-scope="items">
          <!--             <tr> -->
          <th colspan="5"> {{ items.group }}</th>
          <th>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text :to="redirectToInscriptionDetail(items.items[0].tutor_id)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Afficher détail</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text @click="selectInscription(items.items)">
                  <v-icon>mdi-credit-card-plus</v-icon>
                </v-btn>
              </template>
              <span>Ajouter paiement</span>
            </v-tooltip>
          </th>
          <!--             </tr> -->
        </template>

        <template slot="item.created_at" slot-scope="item">
          {{ formatedDate(item.item.created_at) }}
        </template>

        <template slot="item.classe" slot-scope="item">{{ item.item.classe }} - {{ item.item.day }}</template>

        <template slot="item.actions" slot-scope="item">
          <v-icon small class="mr-2" @click="editItem(item.item)">mdi-pencil</v-icon>
          <v-icon v-if="item.item.status == 'pending'" small @click="deleteItem(item.item)">mdi-delete</v-icon>
        </template>

        <template slot="item.status" slot-scope="item">
          <v-chip :color="getStatusColor(item.item.status)">
            <span class="white--text">{{ $t(item.item.status) }}</span>
          </v-chip>
        </template>
      </v-data-table>

      <v-dialog v-model="dialogPaiementCash" width="500">
        <v-card class="" :loading="loading">
          <v-card-title>
            <span class="headline">Ajout d'un paiement</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="paiementForm" v-model="paiementValid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="somme" label="Montant*" hide-details="auto"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="value_date" label="Date du paiement*" hide-details="auto"
                    :rules="[rules.dateFormat]"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="closeDialogPaiement">{{ $t('actions.cancel') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!paiementValid" @click="payer">Payer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn fab bottom right color="primary" fixed x-large to="/school/1/inscription/new">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import { APIServices } from "@/api/api";
export default {
  data: () => ({
    dialogPaiementCash: false,
    paiementValid: false,
    somme: null,
    inscriptionsSaved: null,
    value_date: null,
    snackbarError: null,
    headersNew: [
      { text: "Category", value: "category" },
      { text: "Dessert (100g serving)", value: "name" },
      { text: "Calories", value: "calories" },
      { text: "Fat (g)", value: "fat" },
      { text: "Carbs (g)", value: "carbs" },
      { text: "Protein (g)", value: "protein" },
      { text: "Iron (%)", value: "iron" }
    ],
    desserts: [
      {
        value: false,
        name: "Orange Juice",
        category: "Beverage",
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: "7%"
      },
      {
        value: false,
        name: "Larabar",
        category: "Snack",
        calories: 408,
        fat: 3.2,
        carbs: 87,
        protein: 6.5,
        iron: "45%"
      },
      {
        value: false,
        name: "Donut",
        category: "Breakfast",
        calories: 452,
        fat: 25.0,
        carbs: 51,
        protein: 4.9,
        iron: "22%"
      },

      {
        value: false,
        name: "Bagel",
        category: "Breakfast",
        calories: 999,
        fat: 28.0,
        carbs: 151,
        protein: 2.9,
        iron: "29%"
      },
      {
        value: false,
        name: "KitKat",
        category: "Snack",
        calories: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7,
        iron: "6%"
      }
    ],
    groupSortDescending: false,
    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 4,
      sortBy: "created_at",
      totalItems: 0,
      rowsPerPageItems: [1, 2, 4, 8, 16]
    },
    dialog: false,
    search: "",
    nameRules: [v => !!v || "Name is required"],
    inscriptions: [],
    editedIndex: -1,
    editedItem: {
      gender: "",
      lastname: null,
      firstname: "",
      dateofbirth: "",
      address: "",
      zip: "",
      city: "",
      email: "",
      mobile: "",
      photo: "https://cdn.vuetifyjs.com/images/profiles/marcus.jpg"
    },
    defaultItem: {
      gender: "",
      lastname: null,
      firstname: "",
      dateofbirth: "",
      address: "",
      zip: "",
      city: "",
      email: "",
      mobile: "",
      photo: ""
    },
    teacher: {
      name: ""
    },
    error: null,
    totalInscriptions: 0,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: [false]
    },
    loading: false,
    snackbar: false,
    classes: null,
    students: null,
    rules: {
      required: value => !!value || "Champs obligatoire",
      minSomme: value => {
        var inscriptions = 0;
        if (this.inscriptionsSaved) {
          inscriptions = this.inscriptionsSaved.length;
        }
        console.log("value : " + value);
        console.log("inscriptions : " + inscriptions);
        if (inscriptions == 1) {
          return value == 200 || "Somme de 200€ exact";
        }
        if (inscriptions == 2) {
          return value >= 200 || "Somme min de 200€";
        }
        if (inscriptions >= 3) {
          return value >= 67 || "Somme min de 67€";
        } else {
          return true;
        }
      },
      dateFormat: value => {
        const pattern = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/;
        return pattern.test(value) || "Date invalide.";
      }
    }
  }),
  created() {
    this.loading = true;
    this.loadClasses();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Tutor" : "Modification de l'inscription";
    },
    headers() {
      return [
        { text: "Date d'inscription", value: "created_at", sortable: false },
        { text: this.$t("lastname"), value: "last_name", sortable: false },
        { text: this.$t("firstname"), value: "first_name", sortable: false },
        { text: this.$t("classe"), value: "classe", sortable: false },
        { text: this.$t("status"), value: "status", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ];
    },
    computedItems() {
      return this.classes.map(item => {
        var disabled = true;
        if (item.nb_students >= item.max_students) {
          disabled = true;
        } else {
          disabled = false;
        }
        item.disabled = disabled;
        console.log(item);
        return {
          item: item,
          disabled: disabled
        };
      });
    }
  },

  watch: {
    dialog(val) {
      console.log("watch dialog :" + this.dialog);
      val || this.close();
    },
    options: {
      handler() {
        this.getDataFromApi().then(data => {
          this.inscriptions = data.items;
          this.totalInscriptions = data.total;
        });
      },
      deep: true
    },
    search(val) {
      console.log(val);
      this.getDataFromApi().then(data => {
        this.inscriptions = data.items;
        this.totalInscriptions = data.total;
      });
    }
  },
  mounted() {
    this.getDataFromApi().then(data => {
      this.inscriptions = data.items;
      this.totalInscriptions = data.total;
    });
  },

  methods: {
    formatedDate(date) {
      return this.$moment(date).format("Do MMMM YYYY");
    },
    selectInscription(item) {
      //this.editedIndex = this.inscriptions.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      //console.log(item);
      this.inscriptionsSaved = item;

      this.dialogPaiementCash = true;
    },
    payer() {
      if (this.$refs.paiementForm.validate()) {
        var total = this.inscriptionsSaved.length * 220;
        console.log("total à payer : " + total);
        console.log("somme payée : " + this.somme);
        this.loading = true;


        var montantParEnfant = Math.floor(this.somme / this.inscriptionsSaved.length);
        var reste = this.somme % this.inscriptionsSaved.length;

        var amount = this.somme / this.inscriptionsSaved.length;
        var start_date = "2023-09-01";
        var end_date = "2024-05-31";
        var value_date = this.$moment(this.value_date, "DD/MM/YYYY").format("YYYY-MM-DD");

        for (var key in this.inscriptionsSaved) {
          console.log("inscription key : " + key + " et inscription : " + this.inscriptionsSaved[key]);
          console.log(this.inscriptionsSaved[key].id);

          console.log("start_date : " + start_date);
          console.log("end_date : " + end_date);
          console.log("amount : " + amount);

          if (key == 0) {
            montantParEnfant = montantParEnfant + reste;
          } else {
            montantParEnfant = Math.floor(this.somme / this.inscriptionsSaved.length);
          }

          APIServices.post(`/tutors/${this.inscriptionsSaved[key].tutor_id}/students/${this.inscriptionsSaved[key].student_id}/inscriptions/${this.inscriptionsSaved[key].id}/contributions`, {
            start_date: start_date,
            end_date: end_date,
            amount: montantParEnfant,
            value_date: value_date
          })
            .then(response => {
              console.log(response.data);
              this.loading = false;
              this.dialogPaiementCash = false;
              this.loadInscriptions();
            })
            .catch(e => {
              this.error = e.response;
              console.log("echec de création d inscription");
              console.log(e.response);
              this.loading = false;
              this.snackbarError = true;
              this.dialogPaiementCash = false;
            });
        }
      }
      //this.loading = false;
    },
    redirectToInscriptionDetail(inscriptionId) {
      return "/school/1/inscription-detail/" + inscriptionId;
    },
    loadInscriptions() {
      this.getDataFromApi().then(data => {
        this.inscriptions = data.items;
        this.totalInscriptions = data.total;
      });
    },
    getStudentName(inscription) {
      return inscription.last_name + " " + inscription.first_name;
    },
    loadClasses() {
      console.log("loading classes");
      this.loading = true;
      APIServices.get("/classes")
        .then(response => {
          console.log(response);
          this.classes = Object.keys(response.data).map(key => {
            return response.data[key];
          });
          this.classes.map(item => {
            var disabled = true;
            if (item.nb_students >= item.max_students) {
              disabled = true;
            } else {
              disabled = false;
            }
            item.disabled = disabled;
            console.log(item);
            return {
              item: item,
              disabled: disabled
            };
          });
          this.loading = false;
        })
        .catch(e => {
          this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    getStatusColor(status) {
      if (status === "pending") {
        return "orange";
      }
      if (status === "pre") {
        return "purple";
      }
      if (status === "default") {
        return "red";
      }
      if (status === "in_order") {
        return "green";
      }
      if (status === "complete") {
        return "teal";
      }
      if (status === "terminated") {
        return "grey";
      }
    },
    loadingInscriptions() {
      this.getDataFromApi().then(data => {
        this.inscriptions = data.items;
        this.totalInscriptions = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise(resolve => {
        /* let search = this.search.trim().toLowerCase();*/

        let items = this.getJsonData().then(response => {
          items = response.data;

          const total = response.total;

          setTimeout(() => {
            this.loading = false;
            resolve({
              items,
              total
            });
          }, 300);
        });
      });
    },

    getJsonData() {
      //  Aqui pra ficar completo falta o parametro de filtro que a
      // a API so SWAPI não me da...
      var sortDesc = null;
      if (this.options.sortDesc[0]) {
        sortDesc = "asc";
      } else {
        sortDesc = "desc";
      }
      console.log(`/inscriptions?page=${this.options.page}&per_page=${this.options.itemsPerPage}&sort_by=${this.options.sortBy}&sort_desc=${sortDesc}&search=${this.search}`);
      return APIServices.get(`/inscriptions?page=${this.options.page}&per_page=${this.options.itemsPerPage}&sort_by=${this.options.sortBy}&sort_desc=${sortDesc}&search=${this.search}`)
        .then(function (response) {
          var result = response.data;
          //console.log(result);
          return result;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },

    updatePagination(pagination) {
      console.log("update:pagination", pagination);
    },

    initialize() {
      this.loading = true;
      APIServices.get("/inscriptions")
        .then(response => {
          console.log(response);
          this.inscriptions = response.data.data;
          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.inscriptions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem);
      this.dialog = true;
    },

    deleteItem(item) {
      confirm("Are you sure you want to delete this item?") && this.deleteInscription(item);
    },
    deleteInscription(item) {
      this.loading = true;
      APIServices.delete("/inscriptions/" + item.id)
        .then(response => {
          console.log(response);
          this.snackbar = true;
          this.loadingInscriptions();
          this.loadClasses();
          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
      this.file = null;
      this.error = null;
    },
    closeDialogPaiement() {
      this.dialogPaiementCash = false;
      this.somme = null;
      this.value_date = null;
    },

    clear() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.file = null;
    },

    save() {
      this.loading = true;
      console.log(this.editedIndex);
      if (this.editedIndex > -1) {
        console.log("Mode edition");
        APIServices.put(`/inscriptions/` + this.editedItem.id, this.editedItem)
          .then(response => {
            this.snackbar = true;
            console.log(response.data);
            this.tutor = response.data;
            Object.assign(this.inscriptions[this.editedIndex], this.editedItem);
            this.loading = false;

            this.loadInscriptions();
            this.loadClasses();

            this.close();
          })
          .catch(e => {
            this.error = e.response.data;
            console.log(e.response.data);
            this.loading = false;
          });
      }
    }
  }
};
</script>

<style></style>
